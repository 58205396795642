import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import { campaignClient, publicCampaignClient, sessionClient } from "../../api";
import { PageLoader, Table } from "../../Components";
import { filecolumns } from "./FilesCol";
import { showToast } from "../../Components/Toast/ToastManager";

const FileUploadComp = () => {
    const [isOpen, setIsOpen] = useState(false);

    const inputEl = useRef<HTMLInputElement | null>(null);

    const [uploading, setUploading] = useState(false);
    const [selectedRows, setSelectedRows] = React.useState({});
    const handleSelection = React.useCallback((value: any) => {
        setSelectedRows(value);
    }, []);

    const {
        isLoading: loadingFiles,
        data: files,
        refetch: refetchFiles,
    } = useQuery(
        "getFiles",
        async () => {
            const res: { files: any[] } = await publicCampaignClient.call("getFiles", {
                dataType: "swipe",
            });
            return res.files;
        },
        { refetchOnWindowFocus: false }
    );

    async function uploadPdf(event: any) {
        setUploading(true);

        const s3Key = await sessionClient.uploadFile({
            data: event.target.files[0],
            contentType: ".pdf",
        });
        if (s3Key) {
            const { result } = await campaignClient.call<{ result: boolean }>("saveFile", {
                key: s3Key,
                filename: event.target.files[0].name,
                filetype: event.target.files[0].type,
                fileSize: event.target.files[0].size,
                dataType: "swipe",
            });
            if (result) {
                setIsOpen(false);
                refetchFiles();
                setUploading(false);
                showToast({
                    content: "Saved.",
                    duration: 3000,
                    error: false,
                });
            }
        }
    }

    const deleteFile = async (key: string) => {
        if (!key || uploading) {
            return;
        }
        setUploading(true);
        const result = await campaignClient.call("deleteFile", { key });
        if (result) {
            refetchFiles();
            setUploading(false);
        }
    };

    const fileColumns = filecolumns({ deleteFile });

    if (uploading || loadingFiles) {
        return <PageLoader />;
    }

    return (
        <>
            <div className="main__head" style={{ display: "flex", justifyContent: "space-between" }}>
                <h2 className="main__title">Upload files</h2>
                <div>
                    <button
                        disabled={Object.keys(selectedRows).length ? false : true}
                        className="button__outline"
                        onClick={async () => {
                            if (files && selectedRows && Object.keys(selectedRows)) {
                                const ids = Object.keys(selectedRows);
                                await Promise.all(
                                    ids.map(async (r) => {
                                        await deleteFile(files[+r].key);
                                    })
                                );
                            }
                        }}>
                        Delete
                    </button>
                    <button
                        className="btn__success"
                        disabled={uploading ? true : false}
                        onClick={() => {
                            if (inputEl !== null && inputEl.current !== null) {
                                inputEl!.current!.click?.();
                            }
                        }}>
                        {uploading ? "uploading" : "Upload file"}
                    </button>
                    <input style={{ display: "none" }} type="file" onChange={uploadPdf} id="upload" name="upload" accept="application/pdf" ref={inputEl} />
                </div>
            </div>
            <p>
                Please make sure file names consist of region ("ATL", "CORPORATE", "FRANCHISE"), language ("_EN", "_FR"), type ("RULES", "WINNER") to show appropriately in the
                website.
            </p>
            <div className="main__body main__body--flex main__body--flex-alt">
                <div className="tabs__body mt-5">
                    <div className="table table--alt table--tabs table--big">
                        {files && files?.length ? (
                            <Table columns={fileColumns} data={files ? files : []} tablePageSize={15} checkbox={true} onSelectedRowsChange={handleSelection} />
                        ) : (
                            <p style={{ textAlign: "center" }}>There are no records to display</p>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default FileUploadComp;
