import { useState } from "react";
import "./index.css";
import { useForm } from "react-hook-form";
import { campaignClient, publicCampaignClient } from "../../api";
import { useQuery } from "react-query";
import { PageLoader } from "../../Components";
import { showToast } from "../../Components/Toast/ToastManager";

type Winners = {
    banner: string;
    week: number;
    storeNumber: string;
    storeName: string;
    swipeRate: string;
};

type FormType = {
    region: string;
    winners: Winners[];
};

type form = {
    region: string;
    banners: {
        [key: string]: {
            week: number;
            storeNumber: string;
            storeName: string;
            swipeRate: string;
        }[];
    };
};

const Regions = [
    {
        name: "Atlantic",
        value: "atl",
        banners: ["Sobeys", "Foodland & Co-Op", "Needs", "Lawtons"],
    },
    {
        name: "West",
        value: "west",
        banners: ["Sobeys", "Safeway", "IGA", "Thrifty Foods", "Liquor", "FreshCo", "Chalo FreshCo"],
    },
    {
        name: "Ontario",
        value: "ont",
        banners: ["Sobeys & Urban Fresh", "Foodland & Co-Op", "FreshCo & Chalo!", "FreshCo", "Chalo FreshCo"],
    },
    {
        name: "Quebec",
        value: "quebec",
        banners: ["IGA", "Les Marché Traditions", "Rachelle Béry"],
    },
];

function LeaderBoard({ type }: { type: string }) {
    const [loading, setLoading] = useState(false);
    const defaultWeeks = [1, 2, 3, 4, 5, 6];

    const defaultWinners = defaultWeeks.map((w) => {
        return {
            week: w,
            storeNumber: "",
            storeName: "",
            swipeRate: "",
        };
    });

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        // reset,

        formState: { errors, isDirty },
    } = useForm<form>({
        mode: "onTouched",
        criteriaMode: "all",
        defaultValues: { region: "atl" },
    });

    const {
        isLoading: isLoadingLeaderBoard,
        data: leaderBoard,
        refetch,
    } = useQuery(
        [type === "weekly-swipe" ? "getWeeklySwipe" : "getWeeklyoverall", watch().region, type],
        async () => {
            const res: { leaderboard: FormType } = await publicCampaignClient.call("getSwipeRateWinners", {
                region: watch().region,
                type: type,
            });

            if (res.leaderboard.winners) {
                let banners: { [key: string]: any[] } = {};
                Regions.find((r) => r.value === watch().region)?.banners?.map((b) => {
                    banners[b] = res.leaderboard.winners?.filter((w) => w.banner === b);
                    // Adding additional 2 weeks
                    if (banners[b].length === 4) {
                        banners[b].push(
                            { banner: b, storeName: "", storeNumber: "", swipeRate: "", week: 5 },
                            { banner: b, storeName: "", storeNumber: "", swipeRate: "", week: 6 }
                        );
                    }
                });

                for (let b in banners) {
                    if (banners[b].length < 1) {
                        banners[b] = defaultWinners;
                    }
                }

                setValue("banners", banners);
            } else {
                let banners: { [key: string]: any[] } = {};
                Regions.find((r) => r.value === watch().region)?.banners?.map((b) => {
                    banners[b] = defaultWinners;
                });
                setValue("banners", banners);
            }
        },

        { enabled: !!watch().region && !!type, refetchOnWindowFocus: false }
    );

    const handleSave = async (data: form) => {
        if (loading) {
            return;
        }
        setLoading(true);
        const handledData: FormType = {
            region: data.region,
            winners: Object.keys(data.banners)
                ?.map((b) => {
                    return data.banners[b]?.map((w) => {
                        return { banner: b, ...w };
                    });
                })
                .flat(),
        };

        try {
            const { result } = await campaignClient.call<{ result: boolean }>("saveLeaderboard", {
                ...handledData,
                type: type,
            });
            if (result) {
                refetch();
                showToast({
                    content: "saved.",
                    duration: 3000,
                    error: false,
                });
                setLoading(false);
            }
        } catch (e) {
            showToast({
                content: "error while saving the leaderboard.",
                duration: 3000,
                error: true,
            });
        }
    };

    if (isLoadingLeaderBoard || loading) {
        return <PageLoader />;
    }
    return (
        <>
            <form onSubmit={handleSubmit(handleSave)}>
                <div className="paddingBottom">
                    <label className="form__label" htmlFor="region">
                        <b>Region</b>
                    </label>
                    <select className="form-control" {...register(`region`)} style={{ width: "150px", cursor: "pointer" }}>
                        <option value="">Select</option>

                        {Regions.map((region: { name: string; value: string }) => (
                            <option value={region.value} key={region.value}>
                                {region.name}
                            </option>
                        ))}
                    </select>
                </div>
                <table>
                    {watch().banners &&
                        Object.keys(watch().banners)?.map((banner, i) => (
                            <tbody key={i}>
                                <tr className="tableRowCustom">
                                    <td>{banner.includes("Liquor") ? "Liquor (Sobeys, Safeway, Thrifty Foods)" : banner}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr className="tableRowCustom">
                                    <td></td>
                                    <td>Week</td>
                                    <td>Store Number</td>
                                    <td>store Name</td>
                                    <td>Swipe rate Increase</td>
                                </tr>
                                {watch().banners[banner] ? (
                                    watch().banners[banner]?.map((_, index) => (
                                        <tr key={index} className="tableRowCustom">
                                            <td></td>
                                            <td>Week {1 + index} Winner</td>
                                            <td>
                                                <input type="text" {...register(`banners.${banner}.${index}.storeNumber`)} />
                                            </td>
                                            <td>
                                                <input type="text" {...register(`banners.${banner}.${index}.storeName`)} />
                                            </td>
                                            <td>
                                                <input type="text" {...register(`banners.${banner}.${index}.swipeRate`)} />
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <></>
                                )}
                            </tbody>
                        ))}
                </table>
                <button className="btn__success" type="submit">
                    Save changes
                </button>
            </form>
        </>
    );
}

export default LeaderBoard;
