

function ComingSoon() {
  return (
    <div className='from-access'>

    <h5 className='form__title'>Coming soon</h5>    

  </div>
  )
}

export default ComingSoon