import { useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { campaignClient, publicCampaignClient } from "../../api";
import { PageLoader } from "../../Components";
import { showToast } from "../../Components/Toast/ToastManager";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import { useQuery } from "react-query";
import FileUploadComp from "./UploadFile";
import LeaderBoard from "./LeaderBoard";

import LotteryWinner from "./LotteryWinner";

import { Accordion } from "react-bootstrap";

type FormEvent = {
    calendar: { startDate: string; endDate: string; week: number; announcementDate: string }[];

    grandPrizeDate: string;

    lotteryDate: string;
    top3Date: string;
};

type ReturnCalendar = {
    calendar: Calendar[];
    grandPrizeDate: string;
    lotteryDate: string;
    top3Date: string;
};

type Calendar = {
    week: number;
    endDate: string;
    startDate: string;
    announcementDate: string;
};

const SwipePlus = () => {
    const [loading, setLoading] = useState(false);

    const { isLoading: isLoadingSwipe, data: swipe } = useQuery(
        "getSwipplus",
        async () => {
            const res: ReturnCalendar = await publicCampaignClient.call("getSwipplus", {});
            if (!!res) {
                res?.grandPrizeDate && setValue("grandPrizeDate", res.grandPrizeDate);
                res?.lotteryDate && setValue("lotteryDate", res.lotteryDate);
                res?.calendar && setValue("calendar", res.calendar);
                res?.top3Date && setValue("top3Date", res.top3Date);
            }
            return res;
        },
        { refetchOnWindowFocus: false }
    );

    const {
        handleSubmit,
        control,
        reset,
        watch,
        setValue,
        formState: { errors },
    } = useForm<FormEvent>({
        mode: "onTouched",
        criteriaMode: "all",
        defaultValues: swipe,
    });

    useFieldArray({
        control,
        name: "calendar",
    });

    const handlesave = async (data: FormEvent) => {
        if (loading) {
            return;
        }
        setLoading(true);

        const result = await campaignClient.call("saveSwipePlus", { ...data });

        if (result) {
            showToast({
                content: "Saved",
                duration: 3000,
                error: false,
            });

            setLoading(false);
        }
    };

    if (loading || isLoadingSwipe) {
        return <PageLoader />;
    }
    return (
        <>
            <div className="main__head">
                <h2 className="main__title">Swipe+</h2>
            </div>
            <div className="main__body main__body--flex main__body--flex-alt">
                <form onSubmit={handleSubmit(handlesave)} className="form">
                    {new Array(6).fill("_").map((_, index) => (
                        <div key={index} style={{ maxWidth: "65.8rem", marginBottom: "10px" }}>
                            <h3>
                                <b>Week {index + 1}</b>
                            </h3>
                            <div className="formContent" key={index} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "2rem" }}>
                                <div className="form-group" style={{ display: "flex", flexDirection: "column" }}>
                                    <label className="form__label" htmlFor="date">
                                        Start date
                                    </label>
                                    <Controller
                                        control={control}
                                        name={`calendar.${index}.startDate`}
                                        render={({ field }) => (
                                            <DatePicker
                                                dateFormat="MMMM dd, yyyy"
                                                placeholderText="Select date"
                                                className="form-control"
                                                onChange={(date) => date && field && field.onChange(date?.toISOString())}
                                                selected={field?.value ? new Date(field.value) : null}
                                            />
                                        )}
                                    />
                                    {errors && errors.calendar && errors.calendar[index]?.startDate && (
                                        <div className="error">
                                            <>
                                                <i className="fas fa-exclamation-circle" />
                                                {errors?.calendar[index]?.startDate?.message}
                                            </>
                                        </div>
                                    )}
                                </div>

                                <div className="form-group" style={{ display: "flex", flexDirection: "column" }}>
                                    <label className="form__label" htmlFor="date">
                                        End date
                                    </label>
                                    <Controller
                                        control={control}
                                        name={`calendar.${index}.endDate`}
                                        render={({ field }) => (
                                            <DatePicker
                                                dateFormat="MMMM dd, yyyy"
                                                placeholderText="Select date"
                                                className="form-control"
                                                onChange={(date) => date && field && field.onChange(date?.toISOString())}
                                                selected={field?.value ? new Date(field.value) : null}
                                                minDate={new Date(watch().calendar[index].startDate) || null}
                                            />
                                        )}
                                    />
                                    {errors && errors.calendar && errors.calendar[index]?.endDate && (
                                        <div className="error">
                                            <>
                                                <i className="fas fa-exclamation-circle" />
                                                {errors?.calendar[index]?.endDate?.message}
                                            </>
                                        </div>
                                    )}
                                </div>
                                <div className="form-group" style={{ display: "flex", flexDirection: "column" }}>
                                    <label className="form__label" htmlFor="date">
                                        Announcement date
                                    </label>
                                    <Controller
                                        control={control}
                                        name={`calendar.${index}.announcementDate`}
                                        // rules={{ required: { value: true, message: "Please select a date." } }}
                                        render={({ field }) => (
                                            <DatePicker
                                                dateFormat="MMMM dd, yyyy"
                                                placeholderText="Select date"
                                                className="form-control"
                                                onChange={(date) => date && field.onChange(date?.toISOString())}
                                                selected={field.value ? new Date(field.value) : null}
                                                minDate={new Date(watch().calendar[index].endDate) || null}
                                            />
                                        )}
                                    />
                                    {errors && errors.calendar && errors.calendar[index]?.announcementDate && (
                                        <div className="error">
                                            <>
                                                <i className="fas fa-exclamation-circle" />
                                                {errors?.calendar[index]?.announcementDate?.message}
                                            </>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="form__foot">
                        <ul className="list--buttons" style={{ display: "flex", flexDirection: "column" }}>
                            <li>
                                <div className="form-group" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                    <label className="form__label" htmlFor="date">
                                        Grand Prize Winner Announcement
                                    </label>
                                    <div className="rowControls">
                                        <Controller
                                            control={control}
                                            name={"grandPrizeDate"}
                                            render={({ field }) => (
                                                <DatePicker
                                                    dateFormat="MMMM dd, yyyy"
                                                    placeholderText="Select date"
                                                    className="form-control"
                                                    onChange={(date) => date && field.onChange(date?.toISOString())}
                                                    selected={field.value ? new Date(field.value) : null}
                                                />
                                            )}
                                        />{" "}
                                        <button className="button__outline" type="button" onClick={() => setValue("grandPrizeDate", "")}>
                                            Reset
                                        </button>
                                    </div>

                                    {errors && errors.grandPrizeDate && (
                                        <div className="error">
                                            <>
                                                <i className="fas fa-exclamation-circle" />
                                                {errors?.grandPrizeDate.message}
                                            </>
                                        </div>
                                    )}
                                </div>
                            </li>
                            <li>
                                {" "}
                                <div className="form-group" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                    <label className="form__label" htmlFor="date">
                                        Lottery Prize Draw Announcement
                                    </label>
                                    <div className="rowControls">
                                        <Controller
                                            control={control}
                                            name={"lotteryDate"}
                                            render={({ field }) => (
                                                <DatePicker
                                                    dateFormat="MMMM dd, yyyy"
                                                    placeholderText="Select date"
                                                    className="form-control"
                                                    onChange={(date) => date && field.onChange(date?.toISOString())}
                                                    selected={field.value ? new Date(field.value) : null}
                                                />
                                            )}
                                        />
                                        <button className="button__outline" type="button" onClick={() => setValue("lotteryDate", "")}>
                                            Reset
                                        </button>
                                    </div>
                                </div>
                            </li>
                            <li>
                                {" "}
                                <div className="form-group" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                    <label className="form__label" htmlFor="date">
                                        Top 3 Stores Announcement
                                    </label>
                                    <div className="rowControls">
                                        {" "}
                                        <Controller
                                            control={control}
                                            name={"top3Date"}
                                            render={({ field }) => (
                                                <DatePicker
                                                    dateFormat="MMMM dd, yyyy"
                                                    placeholderText="Select date"
                                                    className="form-control"
                                                    onChange={(date) => date && field.onChange(date?.toISOString())}
                                                    selected={field.value ? new Date(field.value) : null}
                                                />
                                            )}
                                        />
                                        <button className="button__outline" type="button" onClick={() => setValue("top3Date", "")}>
                                            Reset
                                        </button>
                                    </div>
                                    {errors && errors.lotteryDate && (
                                        <div className="error">
                                            <>
                                                <i className="fas fa-exclamation-circle" />
                                                {errors?.lotteryDate.message}
                                            </>
                                        </div>
                                    )}
                                </div>
                            </li>
                            <li>
                                <button className="btn__success" type="submit">
                                    Save changes
                                </button>
                            </li>
                        </ul>
                    </div>
                </form>
            </div>
            <br />
            <br />
            <div className="paddingBottom">
                <FileUploadComp />
            </div>

            <Accordion defaultActiveKey="">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <h2 className="main__title">Weekly - Swipe Rate Growth Winners</h2>
                    </Accordion.Header>
                    <Accordion.Body>
                        <LeaderBoard type="weekly-swipe" />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        <h2 className="main__title">Weekly - Overall Swipe Rate Winners</h2>
                    </Accordion.Header>
                    <Accordion.Body>
                        <LeaderBoard type="weekly-overall" />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>
                        <h2 className="main__title">Grand Prize - Swipe Rate Growth Winners</h2>
                    </Accordion.Header>
                    <Accordion.Body>
                        <LotteryWinner type="grandPrize-swipe" />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>
                        <h2 className="main__title">Grand Prize - Overall Swipe Rate Winners</h2>
                    </Accordion.Header>
                    <Accordion.Body>
                        <LotteryWinner type="grandprize-overall" />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>
                        <h2 className="main__title">Winners Lottery</h2>
                    </Accordion.Header>
                    <Accordion.Body>
                        <LotteryWinner type="lottery-winners" />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>
                        <h2 className="main__title">Target Achievement Lottery</h2>
                    </Accordion.Header>
                    <Accordion.Body>
                        <LotteryWinner type="lottery-target" />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
};

export default SwipePlus;
